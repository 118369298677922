export default [
  {
    questionNumber: 1,
    questionId: 1,
    question: 'Secara fisiografis, wilayah laut Indonesia dibagi menjadi tiga wilayah yaitu Paparan Sunda, Paparan Sahul, dan zona transisi. Paparan Sunda terletak di bagian barat Indonesia dengan ciri … dan meliputi daerah perairan … ',
    options: [
      {
        key: 'a',
        text: 'Terdapat palung; Laut Banda dan Laut Aru',
      },
      {
        key: 'b',
        text: 'Laut dalam; Laut Jawa dan Selat Malaka',
      },
      {
        key: 'c',
        text: 'Laut dangkal; Laut Jawa dan Laut Banda',
      },
      {
        key: 'd',
        text: 'Terdapat palung; Laut Jawa dan Laut Cina Selatan',
      },
      {
        key: 'e',
        text: 'Laut dangkal; Selat Malaka dan Laut Cina Selatan',
      },
    ],
    answer: 'b',
  },
]
